* {
  margin: 0;
  padding: 0; }

a {
  color: #317710;
  transition: color .1s; }
  a:hover, afocus {
    color: #0c1d04; }

input {
  border: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.lozenge-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.lozenge {
  background-color: rgba(49, 119, 16, 0.3);
  border: 1px solid rgba(49, 119, 16, 0.7);
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  padding-right: 3.33333px;
  border-radius: 4px;
  transition: all .2s; }
  .lozenge:hover {
    background-color: rgba(49, 119, 16, 0.7);
    color: #FFF; }
  .lozenge .icon {
    color: rgba(0, 0, 0, 0.3);
    margin-left: 10px; }

.lo__wrapper {
  padding: 0 10px; }

.lo__section {
  margin: 0 auto;
  padding: 10px 0; }
  @media only screen and (min-width: 641px) {
    .lo__section {
      width: 970px; } }
  .lo__section--centered {
    text-align: center;
    margin: 0 auto; }

.lo__image {
  margin: 0;
  padding: 0;
  max-height: 160px;
  overflow: hidden;
  position: relative; }
  .lo__image img {
    max-width: 100%;
    display: block; }
  .lo__image figcaption {
    padding: 5px;
    font-size: 12px;
    font-style: italic;
    color: #317710; }
    @media only screen and (min-width: 641px) {
      .lo__image figcaption {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border-top: 1px solid rgba(49, 119, 16, 0.3); } }

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal__container {
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(49, 119, 16, 0.7);
    border-radius: 5px;
    width: 80%;
    min-height: 30vh;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 10px; }
  .modal__title {
    color: #317710; }
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(49, 119, 16, 0.3);
    border: 1px solid rgba(49, 119, 16, 0.7);
    transition: all .2s;
    border-radius: 3px; }
    .modal__close:hover {
      background-color: rgba(49, 119, 16, 0.7);
      color: #FFF; }
    .modal__close i {
      margin: 0;
      position: relative;
      top: -2px;
      color: #FFF; }

html, body {
  height: 100%;
  overflow: hidden; }

body {
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  background-color: rgba(49, 119, 16, 0.2);
  font-size: 14px; }
  body:before {
    display: block;
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .6;
    z-index: -1;
    background-image: url("../images/tabletop-salt.jpg");
    background-position: top center;
    background-size: cover; }
  body:after {
    display: block;
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0.6); }

.app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto; }

.header__logo {
  width: 100px;
  margin: 0 auto;
  padding: 10px 0; }

.footer {
  text-align: center; }
  .footer li {
    display: inline-block;
    padding: 0 10px; }
    .footer li:last-child {
      border-left: 2px solid rgba(49, 119, 16, 0.5); }

.form__input {
  outline: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  padding: 10px; }

.form__label {
  display: block;
  font-size: 16px;
  padding-bottom: 5px; }

.recipe-item {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 10px; }
  .recipe-item__img {
    border: 1px solid #317710;
    border-radius: 5px;
    margin-right: 10px; }
    @media only screen and (min-width: 641px) {
      .recipe-item__img {
        max-width: 30%;
        min-width: 30%; } }
  @media only screen and (max-width: 640px) {
    .recipe-item {
      flex-direction: column-reverse; }
      .recipe-item__img {
        width: 100%;
        margin-bottom: 10px; }
        .recipe-item__img figcaption {
          display: none; } }

.cupboard__list__item:hover {
  cursor: pointer; }

.search-bar {
  position: relative;
  width: 600px; }
  @media only screen and (max-width: 480px) {
    .search-bar {
      width: 100%; } }
  .search-bar__input {
    border: 1px solid #0c1d04;
    position: relative;
    z-index: 2; }

.suggestions {
  position: absolute;
  z-index: 1;
  margin-top: -2px;
  left: 0;
  right: 0;
  padding-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 3px 3px;
  border: 1px solid rgba(12, 29, 4, 0.9);
  border-width: 0 1px 1px 1px;
  box-shadow: inset 1px 4px 10px -10px;
  text-align: left; }
  .suggestions__items {
    padding: 10px;
    transition: all .2s; }
    .suggestions__items:hover {
      cursor: pointer;
      font-weight: bold;
      color: #317710;
      padding-left: 20px;
      background: rgba(49, 119, 16, 0.1); }
    .suggestions__items + .suggestions__items {
      border-top: 1px solid rgba(12, 29, 4, 0.1); }
